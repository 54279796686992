import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"

const LocationHub = ({ data: { locations }, location }) => {
  return (
    <>
      <SEO
        titleOverride={"Locations We Cover"}
        pathnameOverride={location.pathname}
      />

      <section className="mb-16 lg:mb-32">
        <div className="container">
          <div className="w-full mx-auto mb-8 text-center md:w-2/4 md:mb-0">
            <h1>Locations we cover</h1>
            <p className="mb-3 text-lg md:mb-5">We have generated leads for clients across the UK. Here are a few locations that we mainly operate within.</p>

            <p className="mb-8 text-base md:mb-16">Don’t see your location? Don’t worry; wherever you are based, we can help your business grow and encourage you to contact us to discuss your business needs.</p>
          </div>
        </div>
        <div className="container relative z-10">
          <div className="w-full mx-auto md:w-8/12 xl:w-1/2">
            <div className="">
              <nav>
                <ul className="flex flex-wrap justify-center">
                  {locations.edges.map(({ node }, index) => {
                    return (
                      <li className="block w-full mb-8 text-center md:w-1/3 lg:w-1/4">
                        <Link key={index} to={`/digital-marketing-agency/${node.slug}`} className="block mb-3 text-lg transition duration-200 text-secondary md:text-xl hover:text-primary">
                          <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                          { node.title }
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LocationHub

export const query = graphql`
  query locationHubPageQuery {
    locations: allDatoCmsLocation(sort: {fields: title, order: ASC}) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`